<template>
  <div class="flex justify-between">
    <div
      v-if="card.brand"
      class="flex items-center gap-4"
      :data-processor="pm.processor_name"
    >
      <CreditCardBrand
        v-if="card.brand"
        :brand="card.brand"
      />
      <div>
        {{ card.last4 }}
      </div>
      <div>
        Exp {{ card.exp_month }}/{{ card.exp_year }}
      </div>
      <div
        v-if="isExpired"
        class="tag +red"
      >
        EXPIRED
      </div>
      <!-- <div class="text-gray-400">
        {{ formatSnakeCase(pm.processor_name) }}
      </div> -->
    </div>
    <div
      v-else
      class="font-bold"
    >
      {{ formatSnakeCase(pm.processor_name) }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { isPast } from 'date-fns'
import type { PaymentMethod } from '@rechargeapps/storefront-client'
export interface Props {
  pm: PaymentMethod
}
const props = defineProps<Props>()
// no need for reactivity here
// eslint-disable-next-line vue/no-setup-props-destructure
const card = props.pm.payment_details
const isExpired = isPast(new Date(`${card.exp_year}-${card.exp_month}-01`))
</script>
